const messages = {
	required: 'is required',
	minLength: 'should be least {{minLength}} characters',
	maxLength: 'should be no more then {{ttgMaxLength}} characters',
	pattern: 'should be a valid {{name}}',
	min: 'min value should be {{data-min}}',
};

const extractPiece = (str) => {
	const matches = /{{([^}]*)}}/.exec(str);
	if (!matches) {
		return [null, null];
	}
	return [matches[0], matches[1]];
};

const renderErrorMessage = (type, props, defaultErrorMessage = '') => {
	let string = messages[type];
	if (!string) {
		string = defaultErrorMessage;
	}
	let [value, prop] = extractPiece(messages[type]);
	while (value && prop) {
		const varValue = props[prop] ? props[prop] : '';
		if (!varValue) {
			console.error(`No var value set for ${value}`);
		}
		string = string.replace(value, props[prop] ? props[prop] : '');
		[value, prop] = extractPiece(string);
	}
	return string;
};

export default renderErrorMessage;
