import React from 'react';
import { AppIcon } from './index';

const ErrorMessage = ({ message }) => {
	if (!message) {
		return null;
	}

	return (
		<div className={'flex flex-1 flex-row bg-red-200 text-error rounded-lg p-4 mb-4 shadow-md'}>
			<div className={'mr-2'}>
				<div className={`flex justify-center items-center content-center w-5 h-5 rounded-full bg-error`}>
					<AppIcon icon={'close-line'} className={`text-red-200 text-tiny`} />
				</div>
			</div>
			<p className={'flex items-center'}>{message}</p>
		</div>
	);
};

export default ErrorMessage;
