import React from 'react';
import { AppLayout } from '../../components/Layouts';
import {
	UserChangePassword,
	UserProfileAccordion,
	UserProfileInformation,
	UserSports,
	UserTelegramAccount,
} from '../../components/User';

const PROFILE_KEY = 0;
const SPORTS_KEY = 1;
const TELEGRAM_KEY = 2;
const PASSWORD_KEY = 3;

const Profile = () => {
	const [opened, setOpened] = React.useState(0);

	return (
		<AppLayout contentClass={'small'}>
			<UserProfileAccordion
				title={"DuAssis' Profile"}
				opened={opened === PROFILE_KEY}
				onOpen={() => setOpened(PROFILE_KEY)}
			>
				<UserProfileInformation />
			</UserProfileAccordion>
			<UserProfileAccordion title={'Sport'} opened={opened === SPORTS_KEY} onOpen={() => setOpened(SPORTS_KEY)}>
				<UserSports />
			</UserProfileAccordion>
			<UserProfileAccordion
				title={'Telegram Account'}
				opened={opened === TELEGRAM_KEY}
				onOpen={() => setOpened(TELEGRAM_KEY)}
			>
				<UserTelegramAccount />
			</UserProfileAccordion>
			<UserProfileAccordion
				title={'Change Password'}
				opened={opened === PASSWORD_KEY}
				onOpen={() => setOpened(PASSWORD_KEY)}
			>
				<UserChangePassword />
			</UserProfileAccordion>
		</AppLayout>
	);
};

export default Profile;
