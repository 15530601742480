import React, { useState } from 'react';
import UtilityService from '../../services/UtilityService';
import { AppButton, AppConfirmationModal, AppIcon, AppProgress, AppTooltip } from '../App';
import MatchService from '../../services/MatchService';
import { cancel, cancelMatch, confirm, invite, resend } from '../../store/Match';
import { useDispatch, useSelector } from 'react-redux';
import InvitePlayerModal from './InvitePlayerModal';
import ErrorMessage from '../App/ErrorMessage';
import LocationService from '../../services/LocationService';

const Details = ({ match }) => {
	const [isInviting, setIsInviting] = useState(false);
	const [isCanceling, setIsCanceling] = useState(false);
	const dispatch = useDispatch();

	const { user, loadingAction, canceling } = useSelector(({ user, match }) => ({
		user: user.user,
		loadingAction: match.loadingAction,
		canceling: match.canceling,
	}));

	if (!match || !match.id) {
		return null;
	}

	const renderStatus = (player) => {
		if (MatchService.hasPlayerConfirmed(player)) {
			return <AppIcon className={'text-xl text-primary'} icon={'check-line'} />;
		}
		if (MatchService.hasPlayerRejected(player)) {
			return <AppIcon className={'text-xl text-error'} icon={'close-line'} />;
		}
		return <AppIcon className={'animate-spin text-lg'} icon={'loader-4-line'} />;
	};

	const _invite = async (payload) => {
		const { type } = await dispatch(
			invite({
				matchId: match.id,
				payload,
			})
		);
		if (type === 'invite/fulfilled') {
			setIsInviting(false);
		}
	};

	const _cancelMatch = async () => {
		const { type } = await dispatch(cancelMatch(match.id));
		if (type === 'cancelMatch/fulfilled') {
			setIsCanceling(false);
		}
	};

	const confirmedPlayers = match.players.filter((p) => MatchService.hasPlayerConfirmed(p)).length;
	const awaitingResponsePlayers = match.players.filter((p) => !MatchService.hasPlayerResponded(p)).length;

	return (
		<>
			<div>
				{match.status === 'CANCELED' && (
					<ErrorMessage
						message={
							user && user.id === match.creator_id
								? "You've canceled this match."
								: 'This match has been canceled. Please check in with the match organizer for more information.'
						}
					/>
				)}
			</div>
			<div className={'flex flex-col md:flex-row'}>
				<div className='flex flex-1 flex-col'>
					<p className={'text-2xl'}>{match.name}</p>
					<div className='flex flex-1 items-center'>
						<AppIcon icon={'time-line'} />
						<span>{UtilityService.formatDate(match.starts_at)}</span>
					</div>
					<div className='flex flex-1 items-center'>
						<a
							className={'underline text-primary'}
							href={LocationService.generateLocationMapUrl(match.location)}
							target={'_blank'}
							rel='noreferrer'
						>
							<AppIcon icon={'location-line'} />
							{match.location.name}
						</a>
					</div>
				</div>
				{match.can_edit && (
					<div className={'mt-4 md:mt-0'}>
						<AppButton
							className={'btn btn-warning mr-2'}
							text={'Cancel'}
							icon={'close-line'}
							onClick={() => setIsCanceling(true)}
						/>
						<AppButton text={'Invite'} icon={'add-line'} onClick={() => setIsInviting(true)} />
					</div>
				)}
			</div>
			<AppProgress progress={confirmedPlayers} total={match.number_of_players} />
			{awaitingResponsePlayers > 0 && (
				<span>
					Awaiting {awaitingResponsePlayers} player{awaitingResponsePlayers === 1 ? '' : 's'}
				</span>
			)}

			{match.players.map((player, index) => {
				return (
					<div
						id={'match-details-player-container'}
						className={'flex flex-row py-1 border-b-1 border-gray-300'}
						key={player.id}
					>
						<div className='flex flex-1 items-center'>
							<span>{renderStatus(player)}</span>
							<span className={'mx-1'}>
								{index + 1}. {player.name}
							</span>
						</div>
						{(match.can_edit || player.can_edit) && (
							<div className='flex'>
								{match.can_edit && !MatchService.hasPlayerResponded(player) && (
									<AppTooltip content={'Resend telegram invitation'}>
										<AppButton
											className={'btn btn-info btn-small'}
											icon={'send-plane-line'}
											onClick={() => dispatch(resend(player.id))}
											loading={loadingAction[player.id]}
										/>
									</AppTooltip>
								)}
								{!MatchService.hasPlayerConfirmed(player) && (
									<AppTooltip content={'Accept invitation'}>
										<AppButton
											className={'btn btn-primary btn-small ml-1'}
											icon={'check-line'}
											onClick={() =>
												dispatch(
													confirm({
														matchId: match.id,
														playerId: player.id,
													})
												)
											}
											loading={loadingAction[player.id]}
										/>
									</AppTooltip>
								)}
								{!MatchService.hasPlayerRejected(player) && (
									<AppTooltip content={'Reject invitation'}>
										<AppButton
											className={'btn btn-warning btn-small ml-1'}
											icon={'close-line'}
											onClick={() =>
												dispatch(
													cancel({
														matchId: match.id,
														playerId: player.id,
													})
												)
											}
											loading={loadingAction[player.id]}
										/>
									</AppTooltip>
								)}
							</div>
						)}
					</div>
				);
			})}
			<InvitePlayerModal isOpen={isInviting} onClose={() => setIsInviting(false)} onInvite={_invite} />
			<AppConfirmationModal
				isOpen={isCanceling}
				loading={canceling}
				question={'Are you sure you want to cancel this match? This action is irrevocable.'}
				onConfirm={_cancelMatch}
				onCancel={() => setIsCanceling(false)}
			/>
		</>
	);
};

export default Details;
