import { useCallback, useEffect } from 'react';
import Router from './router';
import { AppLoading } from './components/App';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from './store/User';
import { getMostUsedLocations } from './store/Location';
import { Toaster } from 'react-hot-toast';

const App = ({ ...props }) => {
	const loading = useSelector(({ user }) => user.initialLoading);
	const dispatch = useDispatch();

	const _getUserData = useCallback(() => {
		dispatch(getUserData());
		dispatch(getMostUsedLocations());
	}, [dispatch]);

	useEffect(() => {
		_getUserData();
	}, [_getUserData]);

	if (loading) {
		return <AppLoading />;
	}

	return (
		<>
			<Toaster position='bottom-right' />
			<Router {...props} />
		</>
	);
};

export default App;
