import AppModal from './Modal';
import { AppButton } from './index';

const ConfirmationModal = ({ isOpen, question, onConfirm, onCancel, loading }) => {
	return (
		<AppModal isOpen={isOpen} onRequestClose={onCancel}>
			<div className={'flex flex-col max-w-screen-sm items-center justify-center'}>
				<p>{question}</p>
				<div className='flex justify-end mt-4'>
					<AppButton
						className={'btn w-16 ml-2 btn-primary btn-small'}
						text={'Yes'}
						onClick={onConfirm}
						loading={loading}
					/>
					<AppButton
						className={'btn w-16 ml-2 btn-secondary btn-small'}
						text={'No'}
						onClick={onCancel}
						loading={loading}
					/>
				</div>
			</div>
		</AppModal>
	);
};

export default ConfirmationModal;
