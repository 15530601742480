import React, { useCallback, useEffect } from 'react';
import { AppButton, AppIcon, AppInput } from '../App';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { setTelegramAccount } from '../../store/User';

const TelegramAccount = () => {
	const { user, savingTelegramAccount } = useSelector(({ user }) => ({
		user: user.user,
		savingTelegramAccount: user.savingTelegramAccount,
	}));

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm();

	const eraseForm = useCallback(() => {
		setValue('username', user.telegram ? user.telegram.username : '');
	}, [setValue, user.telegram]);

	useEffect(() => {
		eraseForm();
	}, [eraseForm]);

	const dispatch = useDispatch();

	const _setTelegramAccount = (data) => {
		if (!user.telegram || (user.telegram && user.telegram.username && !user.telegram.chat_id)) {
			dispatch(setTelegramAccount(data));
		}
	};

	return (
		<div className={'mb-4'}>
			Be synced on real time by receiving message updates on Telegram. Verify your Telegram by following two easy:
			<ol>
				<li>
					1. Submit your Telegram username on the input below. If you can't find your username on Telegram, go
					to Telegram Settings, click Edit and setup an username.
				</li>
				<li>2. Send a message to our Telegram Bot in order to confirm your account.</li>
			</ol>
			<form className={'w-full'} onSubmit={handleSubmit(_setTelegramAccount)}>
				<AppInput
					label={'Telegram Username'}
					placeholder={'Telegram Username'}
					className={'w-full'}
					{...register('username', {
						required: true,
					})}
					errors={errors}
					disabled={user.telegram && user.telegram.username && user.telegram.chat_id}
				/>

				<AppButton
					type='submit'
					className={'btn btn-primary w-full mb-4'}
					text={'Save'}
					loading={savingTelegramAccount}
					disabled={user.telegram && user.telegram.username && user.telegram.chat_id}
				/>
			</form>
			{user.telegram && user.telegram.username && !user.telegram.chat_id && (
				<span>
					Click{' '}
					<a
						className={'underline text-primary'}
						href='https://telegram.me/DuAssisBot'
						target={'_blank'}
						rel={'noreferrer'}
					>
						here
					</a>{' '}
					from a device where you have Telegram installed to send us a message on Telegram to finalize your
					account confirmation.
				</span>
			)}
			{user.telegram && user.telegram.chat_id && (
				<div className={'flex md:items-center'}>
					<div className='flex h-6 w-6 bg-primary items-center text-center rounded-full'>
						<AppIcon icon={'check-line'} className={'text-white mx-auto'} />
					</div>
					<p>
						<span className='font-bold text-primary'>&nbsp;Congratulations!</span>&nbsp;The connection to
						your Telegram account is all set!
					</p>
				</div>
			)}
		</div>
	);
};

export default TelegramAccount;
