import React from 'react';

const Label = ({ label, hasError }) => {
	if (!label) {
		return null;
	}
	return <label className={`app-input-label ${hasError ? 'error' : ''}`}>{label}</label>;
};

export default Label;
