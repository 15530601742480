import React from 'react';
import { AppIcon } from '../App';

const Google = ({ onClick, disabled, loading }) => {
	const _onClick = (e) => {
		if (!loading) {
			onClick(e);
		}
	};

	return (
		<button
			className={'flex items-center justify-center w-full bg-white border-2 border-gray-300 p-4'}
			onClick={_onClick}
			disabled={loading || disabled}
		>
			{loading ? (
				<AppIcon className={'animate-spin text-lg'} icon={'loader-4-line'} />
			) : (
				<>
					<AppIcon icon={'google-line'} className={'text-3xl'} />
					<span>Continue with Google</span>
				</>
			)}
		</button>
	);
};

export default Google;
