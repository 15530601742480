import React from 'react';
import { useHistory } from 'react-router-dom';
import UtilityService from '../../services/UtilityService';

const Item = ({ match }) => {
	const history = useHistory();

	const navigateTo = (to) => {
		history.push(to);
	};

	return (
		<div
			className={'underline hover:text-primary cursor-pointer'}
			onClick={() => navigateTo(`/match/${match.slug}`)}
		>
			<span>{match.name}</span>
			<span> - </span>
			<span>{UtilityService.formatDate(match.starts_at)}</span>
		</div>
	);
};

export default Item;
