import React from 'react';
import { AppIcon } from './index';

const Loading = ({ height = 'h-screen' }) => {
	return (
		<div className={`app-loading-spinner ${height}`}>
			<AppIcon className={'animate-spin text-4xl text-primary'} icon={'loader-4-line'} />
		</div>
	);
};

export default Loading;
