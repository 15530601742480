import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import MatchService from '../services/MatchService';
import NotificationService from '../services/NotificationService';

const include = 'location,players';

export const getMatches = createAsyncThunk('getMatches', async () => {
	return await MatchService.get();
});

export const getMatchBySlug = createAsyncThunk('getMatchBySlug', async (slug) => {
	return await MatchService.getBySlug({
		slug,
		include,
	});
});

export const confirm = createAsyncThunk('confirm', async ({ matchId, playerId }) => {
	return await MatchService.confirm(matchId, playerId, {
		include,
	});
});

export const cancel = createAsyncThunk('cancel', async ({ matchId, playerId }) => {
	return await MatchService.cancel(matchId, playerId, {
		include,
	});
});

export const resend = createAsyncThunk('resend', async (playerId) => {
	return await MatchService.resend(playerId);
});

export const invite = createAsyncThunk('invite', async ({ matchId, payload }) => {
	return await MatchService.invite(matchId, payload, {
		include,
	});
});

export const create = createAsyncThunk('create', async ({ payload }) => {
	return await MatchService.create(payload);
});

export const cancelMatch = createAsyncThunk('cancelMatch', async (matchId) => {
	return await MatchService.cancelMatch(matchId, {
		include,
	});
});

export const updateTeams = createAsyncThunk('updateTeams', async (payload) => {
	return await MatchService.updateTeams(payload.matchId, payload.teams, {
		include,
	});
});

const slice = createSlice({
	name: 'Match',
	initialState: {
		matches: [],
		matchDetails: {},
		loading: false,
		loadingAction: {},
		error: '',
		inviting: false,
		inviteError: '',
		creating: false,
		createError: '',
		canceling: false,
		cancelingError: '',
	},
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getMatches.pending, (state) => {
			state.loading = true;
			state.error = '';
		});
		builder.addCase(getMatches.fulfilled, (state, action) => {
			state.matches = action.payload;
			state.loading = false;
		});
		builder.addCase(getMatches.rejected, (state) => {
			state.matches = [];
			state.error = '';
		});

		builder.addCase(getMatchBySlug.pending, (state) => {
			state.loading = true;
			state.error = '';
		});
		builder.addCase(getMatchBySlug.fulfilled, (state, action) => {
			state.matchDetails = action.payload;
			state.loading = false;
		});
		builder.addCase(getMatchBySlug.rejected, (state, action) => {
			state.matchDetails = {};
			state.error = action.error;
			state.loading = false;
		});

		builder.addCase(confirm.pending, (state, action) => {
			state.loadingAction[action.meta.arg.playerId] = true;
		});
		builder.addCase(confirm.fulfilled, (state, action) => {
			state.matchDetails = action.payload;
			state.loadingAction[action.meta.arg.playerId] = false;
		});
		builder.addCase(confirm.rejected, (state, action) => {
			state.error = action.error;
			state.loadingAction[action.meta.arg.playerId] = false;
		});

		builder.addCase(cancel.pending, (state, action) => {
			state.loadingAction[action.meta.arg.playerId] = true;
		});
		builder.addCase(cancel.fulfilled, (state, action) => {
			state.matchDetails = action.payload;
			state.loadingAction[action.meta.arg.playerId] = false;
		});
		builder.addCase(cancel.rejected, (state, action) => {
			state.error = action.error;
			state.loadingAction[action.meta.arg.playerId] = false;
		});

		builder.addCase(resend.pending, (state, action) => {
			state.loadingAction[action.meta.arg] = true;
		});
		builder.addCase(resend.fulfilled, (state, action) => {
			state.loadingAction[action.meta.arg] = false;
		});
		builder.addCase(resend.rejected, (state, action) => {
			state.error = action.error;
			state.loadingAction[action.meta.arg] = false;
		});

		builder.addCase(invite.pending, (state) => {
			state.inviting = true;
			state.inviteError = '';
		});
		builder.addCase(invite.fulfilled, (state, action) => {
			state.matchDetails = action.payload;
			state.inviteError = '';
			state.inviting = false;
		});
		builder.addCase(invite.rejected, (state, action) => {
			state.inviteError = action.error;
			state.inviting = false;
		});

		builder.addCase(create.pending, (state) => {
			state.creating = true;
			state.createError = '';
		});
		builder.addCase(create.fulfilled, (state, action) => {
			state.createError = '';
			state.creating = false;
		});
		builder.addCase(create.rejected, (state, action) => {
			state.createError = action.error;
			state.creating = false;
		});

		builder.addCase(cancelMatch.pending, (state) => {
			state.canceling = true;
			state.cancelError = '';
		});
		builder.addCase(cancelMatch.fulfilled, (state, action) => {
			state.matchDetails = action.payload;
			state.cancelError = '';
			state.canceling = false;
		});
		builder.addCase(cancelMatch.rejected, (state, action) => {
			state.cancelError = action.error;
			state.canceling = false;
		});

		builder.addCase(updateTeams.pending, (state) => {
			state.updatingTeams = true;
			state.updatingTeamsError = '';
		});
		builder.addCase(updateTeams.fulfilled, (state, action) => {
			state.matchDetails = action.payload;
			state.updatingTeamsError = '';
			state.updatingTeams = false;
			NotificationService.success('Teams updated successfully');
		});
		builder.addCase(updateTeams.rejected, (state, action) => {
			state.updatingTeamsError = action.error;
			state.updatingTeams = false;
			NotificationService.success('An error occurred while saving teams.');
		});
	},
});

const { reducer } = slice;

export default reducer;
