import React, { useCallback, useEffect } from 'react';
import { AppButton, AppInput, AppLabel, AppSelect } from '../App';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { updateProfile } from '../../store/User';
import timezones from '../../constants/Timezones';

const ProfileInformation = () => {
	const { user, loading } = useSelector(({ user }) => ({
		user: user.user,
		loading: user.updatingProfile,
	}));

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm();

	const eraseForm = useCallback(() => {
		setValue('name', user.name);
		setValue('surname', user.surname);
		setValue('nickname', user.nickname);
		setValue('timezone', user.timezone);
	}, [setValue, user.name, user.surname, user.nickname, user.timezone]);

	useEffect(() => {
		eraseForm();
	}, [eraseForm]);

	const dispatch = useDispatch();

	const _update = (data) => {
		dispatch(updateProfile(data));
	};

	return (
		<div className={'mb-4'}>
			<form className={'w-full'} onSubmit={handleSubmit(_update)}>
				<AppInput
					label={'Name'}
					placeholder={'Name'}
					className={'w-full'}
					{...register('name', {
						required: true,
					})}
					errors={errors}
				/>
				<AppInput
					label={'Surname'}
					placeholder={'Surname'}
					className={'w-full'}
					{...register('surname', {
						required: true,
					})}
					errors={errors}
				/>
				<AppInput
					label={'Nickname'}
					placeholder={'Nickname'}
					className={'w-full'}
					{...register('nickname', {
						required: true,
					})}
					errors={errors}
				/>
				<AppLabel label={'Timezone'} />
				<AppSelect
					className={'mb-2'}
					options={timezones}
					defaultValue={{ label: user.timezone, value: user.timezone }}
					onChange={(e) => {
						setValue('timezone', e.value);
					}}
				/>
				<p className={'mb-4'}>
					Timezone selection will be used to format all dates within the application & notifications to be
					formatted based on this value.
				</p>
				<AppButton type='submit' className={'btn btn-primary w-full'} text={'Update'} loading={loading} />
			</form>
		</div>
	);
};

export default ProfileInformation;
