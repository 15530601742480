import React from 'react';
import Icon from './Icon';

const Button = ({ text, icon, iconClass, loading = false, className = 'btn btn-primary', ...props }) => {
	const _onClick = (e) => {
		if (loading || props.disabled) {
			if (e) {
				e.preventDefault();
			}
		} else if (props.onClick) {
			props.onClick(e);
		}
	};

	return (
		<button {...props} className={className} onClick={_onClick} disabled={props.disabled || loading}>
			<div className={'flex items-center justify-center relative'}>
				{!!icon && <Icon icon={icon} className={`${iconClass} ${loading ? 'invisible' : 'visible'}`} />}
				<span className={loading ? 'invisible' : 'visible'}>{text}</span>
				{loading && (
					<p className='absolute top-0 left-0 right-0 bottom-0'>
						<Icon icon={'loader-4-line'} className={'animate-spin'} />
					</p>
				)}
			</div>
		</button>
	);
};

export default Button;
