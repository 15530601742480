import { Home, MatchDetails, Matches, Privacy, Profile } from '../views';

// ** Default Route
const AuthorizedDefaultRoute = '/matches';
const UnAuthorizedDefaultRoute = '/login';

const Routes = [
	{
		key: 'home',
		path: '/home',
		component: Home,
	},
	{
		key: 'matches',
		path: '/matches',
		component: Matches,
		exact: true,
	},
	{
		key: 'profile',
		path: '/profile',
		component: Profile,
	},
];

const PublicRoutes = [
	{
		key: 'matchDetails',
		path: '/match/:slug',
		component: MatchDetails,
	},
	{
		key: 'privacy',
		path: '/privacy',
		component: Privacy,
	},
];

export { AuthorizedDefaultRoute, UnAuthorizedDefaultRoute, Routes, PublicRoutes };
