import React from 'react';
import { AppBreakLine, AppIcon } from '../App';

const ProfileAccordion = ({ opened, title, children, onOpen }) => {
	const [isContentVisible, setIsContentVisible] = React.useState(opened);

	React.useEffect(() => {
		if (!opened) {
			setIsContentVisible(false);
		}
	}, [opened]);

	return (
		<div className={'user-profile-accordion'}>
			<div
				className={'flex items-center cursor-pointer'}
				onClick={() => {
					setIsContentVisible(!isContentVisible);
					onOpen();
				}}
			>
				<h1 className='flex flex-1 font-semibold uppercase'>{title}</h1>
				<AppIcon icon={isContentVisible ? 'arrow-up-s-line' : 'arrow-down-s-line'} />
			</div>
			<div className={`content ${isContentVisible ? 'visible' : ''}`}>
				<AppBreakLine text={''} className={'my-2'} />
				{children}
			</div>
		</div>
	);
};

export default ProfileAccordion;
