import ApiService from './ApiService';
import UtilityService from './UtilityService';

const list = (filters) => {
	return new Promise((resolve, reject) => {
		const query = UtilityService.serializeQueryString(filters);
		ApiService.get(`v1/sports?${query}`)
			.then(({ data }) => {
				resolve(data.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const SportService = {
	list,
};

export default SportService;
