import React from 'react';

const HorizontalContent = ({ itemComponent, items }) => {
	return (
		<div className='overflow-auto whitespace-nowrap pb-4'>
			{items.map((item, key) => {
				return (
					<div
						className={`inline-block ${key < items.length - 1 ? 'mr-2' : ''}`}
						style={{ width: '250px' }}
						key={key}
					>
						{itemComponent({ item })}
					</div>
				);
			})}
		</div>
	);
};

export default HorizontalContent;
