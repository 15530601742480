import React from 'react';

const BreakLine = ({ className = '', text = 'OR' }) => {
	return (
		<div className={`relative ${className}`}>
			<hr className={'text-gray-400'} />
			<span className={'absolute text-center w-10 mx-auto bg-white'} style={{ top: -10, left: 0, right: 0 }}>
				{text}
			</span>
		</div>
	);
};

export default BreakLine;
