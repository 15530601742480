import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import LocationService from '../services/LocationService';

export const getMostUsedLocations = createAsyncThunk('getMostUsedLocations', async () => {
	return await LocationService.mostUsed({ include: 'sports' });
});

const slice = createSlice({
	name: 'Location',
	initialState: {
		mostUsed: [],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getMostUsedLocations.fulfilled, (state, action) => {
			state.mostUsed = action.payload;
		});
	},
});

const { reducer } = slice;

export default reducer;
