import React, { useCallback, useEffect } from 'react';
import { AppButton, AppInput } from '../App';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { changePassword } from '../../store/User';

const ChangePassword = () => {
	const { user, loading } = useSelector(({ user }) => ({
		user: user.user,
		loading: user.changingPassword,
	}));

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm();

	const eraseForm = useCallback(() => {
		setValue('username', user.telegram ? user.telegram.username : '');
	}, [setValue, user.telegram]);

	useEffect(() => {
		eraseForm();
	}, [eraseForm]);

	const dispatch = useDispatch();

	const _changePassword = (data) => {
		dispatch(changePassword(data));
	};

	return (
		<div className={'mb-4'}>
			<form className={'w-full'} onSubmit={handleSubmit(_changePassword)}>
				<AppInput
					label={'Password'}
					placeholder={'Password'}
					className={'w-full'}
					{...register('password', {
						required: true,
					})}
					errors={errors}
					type={'password'}
				/>
				<AppInput
					label={'Password Confirmation'}
					placeholder={'Password Confirmation'}
					className={'w-full'}
					{...register('password_confirmation', {
						required: true,
					})}
					errors={errors}
					type={'password'}
				/>

				<AppButton type='submit' className={'btn btn-primary w-full mb-4'} text={'Change'} loading={loading} />
			</form>
		</div>
	);
};

export default ChangePassword;
