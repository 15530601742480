import React from 'react';
import { AppDropDown, AppIcon, AppTooltip } from '../App';
import LocationService from '../../services/LocationService';

const Item = ({ item }) => {
	const renderSportsInfo = () => {
		if (item.sports && item.sports.length > 0) {
			return (
				<div className='flex flex-row'>
					{item.sports.map(({ name, icon }, index) => {
						return (
							<AppTooltip key={index} content={name}>
								<AppIcon icon={icon} />
							</AppTooltip>
						);
					})}
				</div>
			);
		} else {
			return <span className={'text-tiny'}>No activities are registered for this location.</span>;
		}
	};

	return (
		<div className={'border-1 border-gray-300 p-2'}>
			<div className='flex flex-1'>
				<div className='flex flex-1'>{item.name}</div>
				<AppDropDown
					actions={[
						{
							text: 'View details',
							onClick: () => {
								//TODO
								console.log('VIEW DETAILS');
							},
						},
						{
							text: 'View location',
							onClick: () => {
								window.open(LocationService.generateLocationMapUrl(item), '_blank');
							},
						},
					]}
				>
					<AppIcon icon={'more-line'} />
				</AppDropDown>
			</div>
			<img src={item.image} />
			<div className='mt-2'>{renderSportsInfo(item)}</div>
		</div>
	);
};

export default Item;
