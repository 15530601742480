const MenuItem = ({ text, onClick, isActive }) => {
	const renderContent = () => {
		if (typeof text === 'string') {
			return text;
		}
		return text();
	};

	return (
		<div className={'cursor-pointer hidden md:flex items-center mx-3'} onClick={onClick}>
			<p
				className={`hover:text-primary text-primary uppercase border-b-4  ${
					isActive ? 'border-primary' : 'border-gray-300'
				}  font-semibold`}
			>
				{renderContent()}
			</p>
		</div>
	);
};
export default MenuItem;
