import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import UserReducer from './User';
import MatchReducer from './Match';
import LocationhReducer from './Location';

const reducers = combineReducers({
	user: UserReducer,
	match: MatchReducer,
	location: LocationhReducer,
});

export const store = configureStore({
	reducer: reducers,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});
