import {
	AppAutocomplete,
	AppBreakLine,
	AppButton,
	AppCheckbox,
	AppErrorMessage,
	AppIcon,
	AppModal,
	AppTags,
	AppTooltip,
} from '../App';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { UserAutocompleteOption } from '../User';
import UserService from '../../services/UserService';
import debounce from 'debounce-promise';
import AppLabel from '../App/Label';

const InvitePlayerModal = ({ isOpen, onClose, onInvite }) => {
	const [isAdmin, setIsAdmin] = useState(false);
	const [players, setPlayers] = useState([]);
	const [anonPlayers, setAnonPlayers] = useState([]);

	const { loading, error } = useSelector(({ match }) => ({
		loading: match.inviting,
		error: match.inviteError,
	}));

	const eraseForm = useCallback(() => {
		setIsAdmin(false);
		setPlayers([]);
		setAnonPlayers([]);
	}, [isOpen]);

	useEffect(() => {
		if (isOpen) {
			eraseForm();
		}
	}, [eraseForm, isOpen]);

	const getUsers = async (search) => {
		return UserService.list({ search });
	};

	const _onInvite = () => {
		onInvite({
			is_admin: isAdmin,
			names: anonPlayers.map(({ value }) => value),
			players: players.map(({ id }) => ({ id })),
		});
	};

	return (
		<AppModal isOpen={isOpen} onRequestClose={onClose}>
			<div className={'flex flex-col'}>
				<AppErrorMessage message={error.message} />
				<form className={'w-full'} onSubmit={_onInvite}>
					<AppLabel label={"DuAssis' player"} />
					<AppAutocomplete
						placeholder={'Start typing to search for players...'}
						option={UserAutocompleteOption}
						getOptionValue={(option) => option.id}
						getOptionLabel={(option) => option.fullName}
						loadOptions={debounce(getUsers, 500)}
						isClearable={true}
						cacheOptions={false}
						isMulti
						onChange={(i) => {
							setPlayers(i);
							setAnonPlayers([]);
							if (i.length === 0) {
								setIsAdmin(false);
							}
						}}
					/>
					<div className={'mt-4'}>
						<AppCheckbox
							checked={isAdmin}
							onChange={setIsAdmin}
							disabled={players.length === 0}
							label={() => (
								<div className={'flex items-center'}>
									<span>Are selected players organizers?</span>
									<AppTooltip
										placement={'top'}
										type={'small'}
										content={
											'By checking this, the selected users will have access to add, accept and reject players from this match as well the possibility to cancel the match.'
										}
									>
										<AppIcon icon={'information-fill'} />
									</AppTooltip>
								</div>
							)}
						/>
					</div>
					<AppBreakLine className={'my-6'} text={'OR'} />

					<div className={'flex items-center'}>
						<p>Not registered players</p>
						<AppTooltip
							placement={'top'}
							type={'small'}
							content={
								"You can invite one (or more) players that are not part of DuAssis' to be part of this match by writing their names and pressing Enter/Submit on the keyboard"
							}
						>
							<AppIcon icon={'information-fill'} />
						</AppTooltip>
					</div>
					<AppTags
						value={anonPlayers}
						onChange={setAnonPlayers}
						placeholder={'Start typing and press Enter/Submit on the keyboard.'}
					/>

					<AppButton
						type='submit'
						className={'btn btn-primary w-full mb-4 mt-6'}
						text={'Invite'}
						disabled={players.length === 0 && anonPlayers.length === 0}
						loading={loading}
					/>
				</form>
			</div>
		</AppModal>
	);
};

export default InvitePlayerModal;
