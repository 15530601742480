import moment from 'moment';

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const serializeQueryString = (obj, prefix) => {
	let str = [],
		p;
	for (p in obj) {
		// eslint-disable-next-line no-prototype-builtins
		if (obj.hasOwnProperty(p)) {
			let k = prefix ? prefix + '[' + p + ']' : p,
				v = obj[p];
			if (v !== null && v !== undefined) {
				str.push(
					typeof v === 'object'
						? serializeQueryString(v, k)
						: encodeURIComponent(k) + '=' + encodeURIComponent(v)
				);
			}
		}
	}
	return str.join('&');
};

const formatDate = (date, format = 'DD MMM YYYY HH:mm') => {
	return moment(date).format(format);
};

const UtilityService = {
	serializeQueryString,
	formatDate,
};

export default UtilityService;
