import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logo } from '../../assessts';
import MenuItem from './MenuItem';
import { logout } from '../../store/User';
import { AppHorizontalContent, AppIcon, AppInput, AppModal, AppTooltip } from '../App';
import { useLocation } from 'react-router';
import { LocationItem } from '../Location';

const routes = [
	{
		path: '/home',
		text: 'Home',
	},
	{
		path: '/profile',
		text: 'Profile',
	},
	{
		path: '/matches',
		text: 'Matches',
	},
];

const Header = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();
	const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
	const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

	const { user, mostUsedLocations } = useSelector(({ user, location }) => ({
		user: user.user,
		mostUsedLocations: location.mostUsed,
	}));

	const isLoggedIn = user && !!user.id;

	const navigateTo = (to) => {
		history.push(to);
	};

	const _logout = () => {
		dispatch(logout());
		navigateTo('/');
	};

	useEffect(() => {
		document.addEventListener('keydown', function (event) {
			if ((event.ctrlKey || event.metaKey) && (event.key === 'k' || event.key === 'K')) {
				setIsSearchModalOpen(true);
				event.preventDefault();
			}
		});
	}, []);

	return (
		<nav className='duassis-header'>
			<div className='desktop-container'>
				<div className='flex justify-between'>
					<div className='flex'>
						<div onClick={() => navigateTo('/home')} className='logo-container'>
							<img src={logo} alt='Logo' />
						</div>
						{isLoggedIn && (
							<div className={'hidden md:flex items-center'}>
								{routes.map((route) => (
									<MenuItem
										key={route.path}
										text={route.text}
										onClick={() => navigateTo(route.path)}
										isActive={location.pathname.indexOf(route.path) > -1}
									/>
								))}
							</div>
						)}
					</div>
					{isLoggedIn && (
						<div className='hidden md:flex items-center space-x-3 '>
							<AppTooltip content={'Or press ctrl+k'}>
								<AppIcon onClick={() => setIsSearchModalOpen(true)} icon={'search-line'} />
							</AppTooltip>
							<MenuItem text={'Logout'} onClick={_logout} />
						</div>
					)}
					{isLoggedIn && (
						<div className='md:hidden flex items-center'>
							<button className='outline-none mr-2' onClick={() => setIsSearchModalOpen(true)}>
								<AppIcon icon={'search-line'} />
							</button>
							<button
								className='outline-none'
								onClick={() => setIsMobileMenuVisible(!isMobileMenuVisible)}
							>
								<AppIcon icon={'menu-line'} />
							</button>
						</div>
					)}
				</div>
			</div>
			<div className={`mobile-container ${isMobileMenuVisible ? 'opened' : 'closed'}`}>
				<ul className='flex flex-1 flex-col'>
					{routes.map((route) => {
						if (isLoggedIn) {
							return (
								<li key={route.path}>
									<span
										onClick={() => navigateTo(route.path)}
										className={`block text-sm px-2 py-4 font-semibold uppercase ${
											location.pathname === route.path ? 'text-white bg-primary' : 'text-primary'
										}`}
									>
										{route.text}
									</span>
								</li>
							);
						}
						return null;
					})}
				</ul>
				<ul>
					{isLoggedIn && (
						<li>
							<span
								className='block text-sm text-center px-2 py-4 text-primary font-semibold'
								onClick={_logout}
							>
								Logout
							</span>
						</li>
					)}
				</ul>
			</div>

			<AppModal
				isOpen={isSearchModalOpen}
				onRequestClose={() => {
					setIsSearchModalOpen(false);
				}}
			>
				<p className={'my-4 text-xl font-semibold text-primary'}>Coming up soon!</p>
				<p>Searching for championships, matches where you can join, different posts & who knows what else!</p>
				<AppInput placeholder={'Search...'} disabled={true} />
				{mostUsedLocations.length > 0 && <p className={'uppercase'}>Most popular locations:</p>}
				<AppHorizontalContent items={mostUsedLocations} itemComponent={LocationItem} />
			</AppModal>
		</nav>
	);
};

export default Header;
