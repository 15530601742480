import ApiService from './ApiService';
import UtilityService from './UtilityService';

const list = (filters) => {
	return new Promise((resolve, reject) => {
		const query = UtilityService.serializeQueryString(filters);
		ApiService.get(`v1/locations?${query}`)
			.then(({ data }) => {
				resolve(data.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const mostUsed = (filters) => {
	return new Promise((resolve, reject) => {
		const query = UtilityService.serializeQueryString(filters);
		ApiService.get(`v1/locations/actions/mostUsed?${query}`)
			.then(({ data }) => {
				resolve(data.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const generateLocationMapUrl = (location) => {
	return `https://www.google.com/maps/search/?api=1&query=${location.address.latitude},${location.address.longitude}`;
};

const LocationService = {
	list,
	mostUsed,
	generateLocationMapUrl,
};

export default LocationService;
