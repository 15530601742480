import Axios from 'axios';
import { AUTH_COOKIE_NAME, setAuthCookie } from './UserService';

const unauthorizedCodes = [401, 403];

const skipRedirectionUrls = ['v1/me', 'v1/login'];

const isUnAuthorized = (error) => {
	try {
		return unauthorizedCodes.indexOf(error.response.status) > -1;
	} catch (e) {
		return false;
	}
};

const ApiService = Axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});

const shouldRedirect = (error) => {
	try {
		return skipRedirectionUrls.indexOf(error.response.config.url) > -1;
	} catch (e) {
		return true;
	}
};

const onUnauthorizedResponse = (error) => {
	setAuthCookie('');
	if (shouldRedirect(error)) {
		window.location.href = '/#/login';
	}
};

ApiService.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem(AUTH_COOKIE_NAME);
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		if (isUnAuthorized(error)) {
			onUnauthorizedResponse(error);
		}
		const e = error.response ? error.response.data : error;
		return Promise.reject({
			...e,
			errorCode: error.response.status,
		});
	}
);

ApiService.interceptors.response.use(
	(response) => response,
	(error) => {
		if (isUnAuthorized(error)) {
			onUnauthorizedResponse(error);
		}
		const e = error.response ? error.response.data : error;
		return Promise.reject({
			...e,
			errorCode: error.response.status,
		});
	}
);

export default ApiService;
