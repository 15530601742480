import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UserService from '../services/UserService';
import NotificationService from '../services/NotificationService';

export const login = createAsyncThunk('login', async ({ email, password }) => {
	return await UserService.login({ email, password });
});

export const getUserData = createAsyncThunk('getUserData', async () => {
	return await UserService.getUserData();
});

export const logout = createAsyncThunk('logout', async () => {
	return await UserService.logout();
});

export const loginWithFacebook = createAsyncThunk('loginWithFacebook', async (token) => {
	return await UserService.facebook(token);
});

export const loginWithGoogle = createAsyncThunk('loginWithGoogle', async (token) => {
	return await UserService.google(token);
});

export const setTelegramAccount = createAsyncThunk('setTelegramAccount', async ({ username }) => {
	return await UserService.setTelegramAccount({ username });
});

export const updateProfile = createAsyncThunk('updateProfile', async (data) => {
	return await UserService.updateProfile(data);
});

export const changePassword = createAsyncThunk('changePassword', async (data) => {
	return await UserService.changePassword(data);
});

export const addSportToMyProfile = createAsyncThunk('addSportToMyProfile', async (sportId) => {
	return await UserService.addSport(sportId);
});

export const removeSportToMyProfile = createAsyncThunk('removeSportToMyProfile', async (sportId) => {
	return await UserService.removeSport(sportId);
});

const slice = createSlice({
	name: 'User',
	initialState: {
		initialLoading: true,
		loading: false,
		loginError: '',
		user: {
			telegram: {},
		},
		loadingGoogle: false,
		updatingProfile: false,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(login.pending, (state) => {
			state.loading = true;
			state.loginError = '';
		});
		builder.addCase(login.fulfilled, (state, action) => {
			state.user = action.payload;
			state.loginError = '';
			state.loading = false;
		});
		builder.addCase(login.rejected, (state) => {
			state.user = {};
			state.loading = false;
			state.loginError =
				"We're sorry, but there was an error with your Email/ Password combination. Please try again.";
		});

		builder.addCase(logout.fulfilled, (state) => {
			UserService.removeAuthCookie();
			state.user = {};
		});

		builder.addCase(getUserData.fulfilled, (state, action) => {
			state.user = action.payload;
			state.initialLoading = false;
		});
		builder.addCase(getUserData.rejected, (state) => {
			state.user = {};
			state.initialLoading = false;
		});

		builder.addCase(loginWithFacebook.fulfilled, (state, action) => {
			state.user = action.payload;
		});

		builder.addCase(loginWithGoogle.pending, (state, action) => {
			state.loadingGoogle = true;
		});
		builder.addCase(loginWithGoogle.fulfilled, (state, action) => {
			state.user = action.payload;
			state.loadingGoogle = false;
		});
		builder.addCase(loginWithGoogle.rejected, (state, action) => {
			state.loadingGoogle = false;
		});

		builder.addCase(setTelegramAccount.pending, (state, action) => {
			state.savingTelegramAccount = true;
		});
		builder.addCase(setTelegramAccount.fulfilled, (state, action) => {
			state.user = action.payload;
			state.savingTelegramAccount = false;
			NotificationService.success(
				'Telegram username was successfully set! Please check the next step to finalize account confirmation.'
			);
		});
		builder.addCase(setTelegramAccount.rejected, (state, action) => {
			state.savingTelegramAccount = false;
			NotificationService.error(action.error.message);
		});

		builder.addCase(updateProfile.pending, (state, action) => {
			state.updatingProfile = true;
		});
		builder.addCase(updateProfile.fulfilled, (state, action) => {
			state.user = action.payload;
			state.updatingProfile = false;
			NotificationService.success('Profile information was successfully updated!');
		});
		builder.addCase(updateProfile.rejected, (state, action) => {
			state.updatingProfile = false;
			NotificationService.error(action.error.message);
		});

		builder.addCase(changePassword.pending, (state, action) => {
			state.changingPassword = true;
		});
		builder.addCase(changePassword.fulfilled, (state, action) => {
			state.changingPassword = false;
			NotificationService.success('Your password was successfully changed!');
		});
		builder.addCase(changePassword.rejected, (state, action) => {
			state.changingPassword = false;
			NotificationService.error(action.error.message);
		});

		builder.addCase(addSportToMyProfile.fulfilled, (state, action) => {
			state.user = action.payload;
			NotificationService.success('Your sport interests were successfully updated!');
		});
		builder.addCase(addSportToMyProfile.rejected, (state, action) => {
			NotificationService.error(action.error.message);
		});

		builder.addCase(removeSportToMyProfile.fulfilled, (state, action) => {
			state.user = action.payload;
			NotificationService.success('Your sport interests were successfully updated!');
		});
		builder.addCase(removeSportToMyProfile.rejected, (state, action) => {
			NotificationService.error(action.error.message);
		});
	},
});

const { reducer } = slice;

export default reducer;
