import React, { useState } from 'react';
import { AppOutsideAlerter } from './index';

const DropDown = ({ actions = [], children }) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className='flex justify-center'>
			<div>
				<div className='dropdown-container'>
					<AppOutsideAlerter handleClickOutsideFunction={() => setIsOpen(false)}>
						<div className={'cursor-pointer'} onClick={() => setIsOpen(!isOpen)}>
							{children}
						</div>
						<div className={`action-container ${isOpen ? '' : 'hidden'}`}>
							{actions.map(({ text, onClick }, index) => (
								<div
									key={index}
									className={'cursor-pointer'}
									onClick={() => {
										setIsOpen(false);
										onClick();
									}}
								>
									{text}
								</div>
							))}
						</div>
					</AppOutsideAlerter>
				</div>
			</div>
		</div>
	);
};

export default DropDown;
