import CreatableSelect from 'react-select/creatable';
import React, { useState } from 'react';

const Tags = ({ value, onChange, placeholder }) => {
	const [inputValue, setInputValue] = useState('');

	return (
		<CreatableSelect
			isClearable
			isMulti
			menuIsOpen={false}
			onInputChange={setInputValue}
			onChange={onChange}
			onKeyDown={(event) => {
				if (!inputValue) return;
				switch (event.key) {
					case 'Enter':
					case 'Tab':
						onChange([
							...value,
							{
								label: inputValue,
								value: inputValue,
							},
						]);
						setInputValue('');
						event.preventDefault();
				}
			}}
			inputValue={inputValue}
			placeholder={placeholder}
			value={value}
		/>
	);
};

export default Tags;
