import ApiService from './ApiService';
import UtilityService from './UtilityService';
import moment from 'moment';

const get = () => {
	return new Promise((resolve, reject) => {
		ApiService.get('v1/matches')
			.then((response) => {
				const { data } = response.data;
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const getBySlug = (filters) => {
	return new Promise((resolve, reject) => {
		const query = UtilityService.serializeQueryString(filters);
		ApiService.get(`v1/matches/actions/getBySlug?${query}`)
			.then((response) => {
				const { data } = response.data;
				data.confirmedPlayers = data.players.filter(hasPlayerConfirmed);
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const confirm = (id, match_player_id, filters) => {
	return new Promise((resolve, reject) => {
		const query = UtilityService.serializeQueryString(filters);
		ApiService.post(`v1/matches/${id}/actions/accept?${query}`, { match_player_id })
			.then((response) => {
				const { data } = response.data;
				data.confirmedPlayers = data.players.filter(hasPlayerConfirmed);
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const cancel = (id, match_player_id, filters) => {
	return new Promise((resolve, reject) => {
		const query = UtilityService.serializeQueryString(filters);
		ApiService.post(`v1/matches/${id}/actions/reject?${query}`, { match_player_id })
			.then((response) => {
				const { data } = response.data;
				data.confirmedPlayers = data.players.filter(hasPlayerConfirmed);
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const resend = (match_player_id) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`v1/matchPlayers/${match_player_id}/actions/resend`)
			.then(() => {
				resolve();
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const invite = (id, payload, filters) => {
	return new Promise((resolve, reject) => {
		const query = UtilityService.serializeQueryString(filters);
		ApiService.post(`v1/matches/${id}/actions/invite?${query}`, payload)
			.then((response) => {
				const { data } = response.data;
				data.confirmedPlayers = data.players.filter(hasPlayerConfirmed);
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const create = (payload) => {
	return new Promise((resolve, reject) => {
		payload.starts_at = moment(payload.starts_at).utc().format('YYYY-MM-DD HH:mm:ss');
		ApiService.post(`v1/matches`, payload)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const cancelMatch = (id, filters) => {
	return new Promise((resolve, reject) => {
		const query = UtilityService.serializeQueryString(filters);
		ApiService.post(`v1/matches/${id}/actions/cancel?${query}`)
			.then((response) => {
				const { data } = response.data;
				data.confirmedPlayers = data.players.filter(hasPlayerConfirmed);
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const updateTeams = (id, teams, filters) => {
	return new Promise((resolve, reject) => {
		const query = UtilityService.serializeQueryString(filters);
		ApiService.post(`v1/matches/${id}/actions/updateTeams?${query}`, teams)
			.then((response) => {
				const { data } = response.data;
				data.confirmedPlayers = data.players.filter(hasPlayerConfirmed);
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const hasPlayerConfirmed = (player) => {
	return player.status === 'CONFIRMED';
};

const hasPlayerRejected = (player) => {
	return player.status === 'REJECTED';
};

const hasPlayerResponded = (player) => {
	return player.status !== 'PENDING';
};

const MatchService = {
	get,
	getBySlug,
	confirm,
	cancel,
	resend,
	invite,
	create,
	cancelMatch,
	updateTeams,
	hasPlayerConfirmed,
	hasPlayerRejected,
	hasPlayerResponded,
};

export default MatchService;
