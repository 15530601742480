import React from 'react';
import { AppLayout } from '../../components/Layouts';

const Home = () => {
	return (
		<AppLayout>
			<p>
				Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt dicta eligendi eos illum itaque
				laudantium libero magnam maiores molestiae obcaecati perspiciatis sed sit soluta, ut voluptatum.
				Accusamus beatae enim perferendis.
			</p>
			<p>
				Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab accusamus at atque cupiditate dicta
				dignissimos eaque eos esse ex exercitationem illo, ipsa iusto maiores molestias mollitia odit rem ullam,
				veniam!
			</p>
			<p>
				Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci, atque consequuntur fuga fugit
				numquam vel veniam? Doloremque eveniet maxime, minus mollitia quaerat quo quos repellendus totam veniam
				vitae. Doloremque, reprehenderit.
			</p>
		</AppLayout>
	);
};

export default Home;
