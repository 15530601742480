import React from 'react';
import { AppLayout } from '../../components/Layouts';

const Privacy = () => {
	return (
		<AppLayout>
			<h1 className={'text-3xl'}>Privatësia</h1>
			<h3 className='text-xl'>OBJEKTI I KËSAJ RREGULLOREJE</h3>
			<p>
				Kjo Rregullore ka për objekt informimin e subjekteve të të dhënave personale mbi mënyrën e mbledhjes,
				përpunimit, përhapjes, ruajtjes dhe masave të sigurisë që janë marrë për të dhënat e tyre.
			</p>
			<p>Bazuar në:</p>
			<ol>
				<li>- Kushtetutën e Republikës së Shqipërisë,</li>
				<li>- Ligjin Nr.9887 datë 10.03.2008 ‘Për Mbrojtjen e të Dhënave Personale’,</li>
				<li>
					- Ligjin Nr.9918 datë 19.05.2008 ‘Për Komunikimet Elektronike dhe Postare’ si dhe aktet e tjera
					nënligjore në plotësim.
				</li>
			</ol>
			<p>
				AKSHI kryen përpunimin e ligjshëm të të dhënave personale duke respektuar dhe garantuar të drejtat dhe
				liritë themelore të njeriut dhe, në veçanti, të drejtën e ruajtjes së jetës private.
			</p>
			<h3 className='text-xl'>MBROJTJA E TË DHËNAVE</h3>
			<p>Mbrojtja e të dhënave bazohet në:</p>
			<ol>
				<li>- Përpunimin në mënyrë të drejtë dhe të ligjshme;</li>
				<li>
					- Grumbullimin për qëllime specifike, të përcaktuara qartë, e legjitime dhe në përpunimin në
					përputhje me këto qëllime;
				</li>
				<li>
					- Mjaftueshmërinë e të dhënave, të cilat duhet të lidhen me qëllimin e përpunimit dhe të mos e
					tejkalojnë këtë qëllim;
				</li>
				<li>
					- Saktësinë që të dhënat duhet të kenë dhe, kur është e nevojshme, duhet të përditësohen duhet
					ndërmarrë çdo hap i arsyeshëm për të fshirë apo korrigjuar të dhëna të pasakta apo të paplota, në
					lidhje me qëllimin për të cilin janë mbledhur apo për të cilin përpunohen më tej;
				</li>
				<li>
					- Mbajtjen në atë formë, që të lejojë identifikimin e subjekteve të të dhënave për një kohë, por jo
					më tepër sesa është e nevojshme për qëllimin, për të cilin ato janë grumbulluar ose përpunuar më
					tej.
				</li>
			</ol>
			<h3 className='text-xl'>E DREJTA PËR TË KËRKUAR BLLOKIMIN, KORRIGJIM, FSHIRRJEN</h3>
			<ol>
				<li>
					- Çdo subjekt i të dhënave ka të drejtë të kërkojë bllokimin, korrigjimin ose fshirjen e të dhënave
					pa pagesë, kur vihet në dijeni se të dhënat rreth tij nuk janë të rregullta, të vërteta, të plota
					ose janë përpunuar dhe mbledhur në kundërshtim me dispozitat e këtij ligji.
				</li>
				<li>
					- Kontrolluesi, brenda 30 ditëve nga data e marrjes së kërkesës së subjektit të të dhënave, duhet ta
					informojë atë për përpunimin e ligjshëm të të dhënave, kryerjen ose moskryerjen e bllokimit,
					korrigjimit apo të fshirjes.
				</li>
				<li>
					- Kur kontrolluesi nuk bën bllokimin, korrigjimin ose fshirjen e të dhënave të kërkuara prej tij,
					subjekti i të dhënave ka të drejtë të ankohet te komisioneri.
				</li>
			</ol>
		</AppLayout>
	);
};

export default Privacy;
