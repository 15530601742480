import { useHistory } from 'react-router-dom';

const NotFound = () => {
	const history = useHistory();

	const navigateTo = (to) => {
		history.push(to);
	};

	return (
		<div className={'flex w-full h-screen justify-center items-center content-center'}>
			<div className='flex flex-col justify-center items-center content-center'>
				<p className={'text-4xl'}>[404]</p>
				<p>
					Page not found.{' '}
					<span className={'cursor-pointer underline'} onClick={() => navigateTo('/')}>
						Click here{' '}
					</span>
					to go where it's safe.
				</p>
			</div>
		</div>
	);
};

export default NotFound;
