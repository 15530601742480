import React from 'react';
import { useForm } from 'react-hook-form';
import { AppBreakLine, AppButton, AppIcon, AppInput, AppSection } from '../../components/App';
import { emailRegex } from '../../services/UtilityService';
import { logo } from '../../assessts';
import { login, loginWithGoogle } from '../../store/User';
import { useDispatch, useSelector } from 'react-redux';
import { GoogleLogin } from 'react-google-login';
import { GoogleButton } from '../../components/Auth';

const Login = () => {
	const { loading, loadingGoogle, errorMessage } = useSelector(({ user }) => ({
		loading: user.loading,
		loadingGoogle: user.loadingGoogle,
		errorMessage: user.loginError,
	}));

	const dispatch = useDispatch();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const _login = (credentials) => {
		dispatch(login(credentials));
	};

	const responseGoogle = ({ tokenId }) => {
		dispatch(loginWithGoogle(tokenId));
	};

	return (
		<div className={'flex flex-1 items-center content-center mx-4 md:mx-auto h-screen md:w-1/2 lg:w-1/3 xl:w-1/4 '}>
			<AppSection>
				<img src={logo} alt='' className={'w-32 object-contain mx-auto'} />
				{!!errorMessage && (
					<div className={'flex flex-1 bg-error text-white p-4 shadow-md my-4'}>
						<AppIcon icon={'close-line'} className={'text-4xl'} />
						<p>{errorMessage}</p>
					</div>
				)}
				<form className={'w-full'} onSubmit={handleSubmit(_login)}>
					<AppInput
						label={'Email'}
						placeholder={'Email'}
						errors={errors}
						{...register('email', {
							required: true,
							pattern: {
								value: emailRegex,
							},
						})}
					/>
					<AppInput
						label={'Password'}
						placeholder={'Password'}
						type={'password'}
						errors={errors}
						{...register('password', {
							required: true,
						})}
					/>
					<AppButton
						type='submit'
						className={'btn btn-primary w-full mb-4'}
						text={'Login'}
						loading={loading}
					/>
				</form>
				<AppBreakLine />
				<div className={'flex flex-col text-center w-full mx-auto mt-4 '}>
					{/*<FacebookLogin*/}
					{/*	appId='723618784796179'*/}
					{/*	fields='name,email,picture'*/}
					{/*	callback={(res) => responseFacebook(res)}*/}
					{/*	cssClass='my-facebook-button-class'*/}
					{/*	icon={<AppIcon icon={'facebook-squared'} className={'text-3xl'} />}*/}
					{/*/>*/}
					<div className='mt-4'>
						<GoogleLogin
							clientId='727175353764-72nbvqv049dc0tslf2rsatga65u6url8.apps.googleusercontent.com'
							buttonText='Login'
							onSuccess={responseGoogle}
							onFailure={responseGoogle}
							cookiePolicy={'single_host_origin'}
							render={(renderProps) => (
								<GoogleButton
									loading={loadingGoogle}
									onClick={renderProps.onClick}
									disabled={renderProps.disabled}
								/>
							)}
						/>
					</div>
				</div>
			</AppSection>
		</div>
	);
};

export default Login;
