import React from 'react';
import { AppIcon } from '../App';

const Label = ({ sport, className, onRemove }) => {
	return (
		<div className={`flex flex-row items-center ${className}`} style={{ backgroundColor: sport.bg_color }}>
			<div className='flex flex-1 items-center'>
				<AppIcon
					className={'mx-1'}
					icon={sport.icon ? sport.icon : 'information-line'}
					style={{ color: sport.text_color }}
				/>
				<span style={{ color: sport.text_color }}>{sport.name}</span>
			</div>
			{!!onRemove && (
				<div className={'cursor-pointer'} onClick={onRemove}>
					<AppIcon className={'mx-1'} icon={'close-line'} style={{ color: sport.text_color }} />
				</div>
			)}
		</div>
	);
};

export default Label;
