import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './styles/index.scss';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { store } from './store';

//IMPORT FONTELLO ICONS
import './styles/fontello/animation.css';
import './styles/fontello/fontello.css';
import './styles/fontello/fontello-codes.css';
import './styles/fontello/fontello-embedded.css';
import './styles/fontello/fontello-ie7.css';
import './styles/fontello/fontello-ie7-codes.css';

//IMPORT REMIX ICONS
import 'remixicon/fonts/remixicon.css';

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (module.hot) {
	module.hot.accept();
}
