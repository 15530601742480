import React from 'react';
import { AppIcon } from './index';

const Checkbox = React.forwardRef(({ label, ...props }, ref) => {
	const renderLabel = () => {
		if (typeof label === 'string' || typeof label === 'number') {
			return label;
		}
		return label();
	};

	return (
		<label className={`app-checkbox-container ${props.disabled ? 'disabled' : ''}`}>
			<span>{renderLabel()}</span>
			<input type='checkbox' ref={ref} {...props} onChange={(e) => props.onChange(e.target.checked)} />
			<p className='checkmark'>
				<AppIcon className={'checkmark-icon'} icon={'check-line'} />
			</p>
		</label>
	);
});

export default Checkbox;
