import React, { useEffect } from 'react';
import { AppLayout } from '../../components/Layouts';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getMatchBySlug } from '../../store/Match';
import { MatchDetails, MatchTeams } from '../../components/Match';

const Details = () => {
	const { slug } = useParams();
	const dispatch = useDispatch();

	const { matchDetails, loading, error } = useSelector(({ match }) => ({
		matchDetails: match.matchDetails,
		loading: match.loading,
		error: match.error,
	}));

	useEffect(() => {
		dispatch(getMatchBySlug(slug));
	}, [dispatch, slug]);

	return (
		<AppLayout loading={loading} error={error} contentClass={'small'}>
			<MatchDetails match={matchDetails} />
			{matchDetails && matchDetails.confirmedPlayers && <MatchTeams match={matchDetails} />}
		</AppLayout>
	);
};

export default Details;
