import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('body');

const customStyles = {
	content: {
		top: '40%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'white',
	},
	overlay: {
		backgroundColor: 'rgb(0 0 0 / 70%)',
	},
};

const AppModal = ({ children, ...props }) => {
	return (
		<Modal style={customStyles} closeTimeoutMS={300} {...props}>
			{children}
		</Modal>
	);
};

export default AppModal;
