import React, { useEffect, useState } from 'react';
import { AppLayout } from '../../components/Layouts';
import { useDispatch, useSelector } from 'react-redux';
import { create, getMatches } from '../../store/Match';
import { CreateMatchModal, MatchItem } from '../../components/Match';
import { AppButton } from '../../components/App';

const Matches = () => {
	const [isCreating, setIsCreating] = useState(false);
	const { matches, loading } = useSelector(({ match }) => ({
		matches: match.matches,
		loading: match.loading,
	}));

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getMatches());
	}, [dispatch]);

	const _create = async (payload) => {
		const { type } = await dispatch(create({ payload }));
		if (type === 'create/fulfilled') {
			setIsCreating(false);
			dispatch(getMatches());
		}
	};

	return (
		<AppLayout loading={loading} contentClass={'small'}>
			<div className={'flex flex-row items-center'}>
				<h1 className={'flex flex-1 text-3xl'}>Upcoming matches</h1>
				<AppButton
					className={'btn btn-primary btn-small'}
					icon={'add-line'}
					onClick={() => setIsCreating(true)}
				/>
			</div>
			{matches.map((match) => {
				return <MatchItem key={match.id} match={match} />;
			})}
			<CreateMatchModal isOpen={isCreating} onCreate={_create} onClose={() => setIsCreating(false)} />
		</AppLayout>
	);
};

export default Matches;
