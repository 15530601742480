import ApiService from './ApiService';
import UtilityService from './UtilityService';

export const AUTH_COOKIE_NAME = 'CALORIE_APP_AUTH_COOKIE';

const filters = {
	include: 'telegram,sports',
};

const query = UtilityService.serializeQueryString(filters);

const login = ({ email, password }) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`v1/login?${query}`, { email, password })
			.then((response) => {
				const { token, user } = response.data;
				setAuthCookie(token);
				resolve(user);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const getUserData = () => {
	return new Promise((resolve, reject) => {
		ApiService.get(`v1/me?${query}`)
			.then(({ data }) => {
				const user = data.data;
				resolve(user);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const logout = () => {
	return new Promise((resolve) => {
		ApiService.post('v1/logout').finally(() => {
			resolve();
		});
	});
};

export const facebook = (token) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`v1/facebook?${query}`, { token })
			.then((response) => {
				const { token, user } = response.data;
				setAuthCookie(token);
				resolve(user);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const google = (token) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`v1/google?${query}`, { token })
			.then((response) => {
				const { token, user } = response.data;
				setAuthCookie(token);
				resolve(user);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const list = (filters) => {
	return new Promise((resolve, reject) => {
		const query = UtilityService.serializeQueryString(filters);
		ApiService.get(`v1/users?${query}`)
			.then(({ data }) => {
				resolve(data.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const setTelegramAccount = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`v1/me/actions/setTelegramAccount?${query}`, payload)
			.then(({ data }) => {
				resolve(data.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const updateProfile = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.put(`v1/me/actions/updateProfile?${query}`, payload)
			.then(({ data }) => {
				resolve(data.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const changePassword = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`v1/me/actions/changePassword`, payload).then(resolve, reject);
	});
};

const addSport = (id) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`v1/me/actions/sports/${id}?${query}`).then(({ data }) => {
			resolve(data.data);
		}, reject);
	});
};

const removeSport = (id) => {
	return new Promise((resolve, reject) => {
		ApiService.delete(`v1/me/actions/sports/${id}?${query}`).then(({ data }) => {
			resolve(data.data);
		}, reject);
	});
};

export const setAuthCookie = (token) => {
	localStorage.setItem(AUTH_COOKIE_NAME, token);
};

const removeAuthCookie = () => {
	localStorage.removeItem(AUTH_COOKIE_NAME);
};

const isAdmin = (user) => {
	try {
		return user.role.toUpperCase() === 'ADMIN';
	} catch (e) {
		return false;
	}
};

const UserService = {
	login,
	getUserData,
	logout,
	facebook,
	google,
	list,
	isAdmin,
	removeAuthCookie,
	setTelegramAccount,
	updateProfile,
	changePassword,
	addSport,
	removeSport,
};

export default UserService;
