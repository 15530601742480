import React from 'react';
import { Stage, Layer, Rect, Text, Label } from 'react-konva';
import { AppButton } from '../App';
import { useDispatch, useSelector } from 'react-redux';
import { updateTeams } from '../../store/Match';

const OFFSET = 1;

const Teams = ({ match }) => {
	const [players, setPlayers] = React.useState([]);

	const dispatch = useDispatch();

	const { updatingTeams } = useSelector(({ match }) => ({
		updatingTeams: match.updatingTeams,
	}));

	const [DIMENSIONS, SET_DIMENSIONS] = React.useState({
		SIZE: 500,
		OFFSET,
		PLAYERS: 12,
	});

	const IS_MOBILE = DIMENSIONS.SIZE < 424;

	const PER_ROW = DIMENSIONS.PLAYERS / 2;
	const WIDTH = (DIMENSIONS.SIZE - (PER_ROW + 1) * DIMENSIONS.OFFSET) / PER_ROW;
	const HEIGHT = IS_MOBILE ? 25 : 30;

	const CalculateDistance = (i) => (WIDTH + DIMENSIONS.OFFSET) * i + DIMENSIONS.OFFSET;

	const resetPlayerPosition = () => {
		if (!match || !match.confirmedPlayers) {
			return;
		}
		const preloaded = match && match.teams ? match.teams : {};
		const players = match.confirmedPlayers.map((player, i) => {
			const pieces = player.name.split(' ');

			const name = pieces.length > 1 ? `${pieces[0][0]}.` : '';
			const surname = pieces[pieces.length - 1];

			const fullName = `${name} ${surname}`;
			const shortFullName = `${name} ${surname.substring(0, 5)}`;

			return {
				...player,
				id: player.id,
				x: preloaded[player.id] ? (preloaded[player.id].x * DIMENSIONS.SIZE) / 100 : CalculateDistance(i % 6),
				y: preloaded[player.id]
					? (preloaded[player.id].y * DIMENSIONS.SIZE) / 100
					: i >= 6
					? HEIGHT + 2 * DIMENSIONS.OFFSET
					: DIMENSIONS.OFFSET,
				rotation: 0,
				isDragging: false,
				fullName,
				shortFullName,
				number: i + 1,
			};
		});
		setPlayers(players);
	};

	const calculateDimensions = () => {
		const el = document.getElementById('match-details-player-container');
		if (el && el.clientWidth) {
			SET_DIMENSIONS({
				SIZE: el.clientWidth,
				OFFSET,
				PLAYERS: match.number_of_players,
			});
		}
	};

	React.useEffect(() => {
		if (match && match.confirmedPlayers) {
			calculateDimensions();
		}
	}, [match.confirmedPlayers]);

	React.useEffect(() => {
		resetPlayerPosition();
	}, [DIMENSIONS]);

	React.useEffect(() => {
		window.addEventListener('resize', calculateDimensions);
		return () => window.removeEventListener('resize', calculateDimensions);
	}, []);

	const handleDragStart = (e) => {
		const id = e.target.id();
		setPlayers(
			players.map((star) => {
				return {
					...star,
					isDragging: star.id === id,
				};
			})
		);
	};

	const handleDragEnd = (e) => {
		const id = e.target.id();
		setPlayers(
			players.map((star) => {
				return {
					...star,
					isDragging: false,
					x: star.id === id ? e.target.attrs.x : star.x,
					y: star.id === id ? e.target.attrs.y : star.y,
				};
			})
		);
	};

	const getFillColor = (x, y) => {
		if (y < DIMENSIONS.SIZE / 8) {
			return 'red';
		}
		return x < DIMENSIONS.SIZE / 2 ? 'white' : 'black';
	};

	const getTextColor = (x, y) => {
		if (y < DIMENSIONS.SIZE / 10) {
			return 'white';
		}
		return x < DIMENSIONS.SIZE / 2 ? 'black' : 'white';
	};

	const prepareFormations = () => {
		const teams = {};
		players.map((player) => {
			teams[player.id] = {
				x: (100 * player.x) / DIMENSIONS.SIZE,
				y: (100 * player.y) / DIMENSIONS.SIZE,
			};
		});
		dispatch(
			updateTeams({
				matchId: match.id,
				teams: { teams },
			})
		);
	};

	return (
		<div className={'mt-4 w-full'}>
			<Stage width={DIMENSIONS.SIZE} height={DIMENSIONS.SIZE} className={'field-container'}>
				<Layer>
					{players.map((player, idx) => (
						<Label
							key={player.id}
							id={player.id}
							x={player.x}
							y={player.y}
							numPoints={5}
							innerRadius={20}
							outerRadius={40}
							fill='#89b717'
							opacity={0.8}
							draggable={!!match.can_edit}
							rotation={player.rotation}
							shadowColor='black'
							shadowBlur={10}
							shadowOpacity={0.6}
							shadowOffsetX={player.isDragging ? 10 : 5}
							shadowOffsetY={player.isDragging ? 10 : 5}
							scaleX={player.isDragging ? 1.1 : 1}
							scaleY={player.isDragging ? 1.1 : 1}
							onDragStart={handleDragStart}
							onDragEnd={handleDragEnd}
						>
							<Rect width={WIDTH} height={HEIGHT} fill={getFillColor(player.x, player.y)} />
							{!IS_MOBILE && (
								<Text
									x={0}
									y={3}
									text={player.number}
									fill={getTextColor(player.x, player.y)}
									align='center'
									width={WIDTH}
									height={HEIGHT}
								/>
							)}
							<Text
								x={0}
								y={IS_MOBILE ? HEIGHT / 2 - 5 : HEIGHT / 2}
								text={IS_MOBILE ? player.shortFullName : player.fullName}
								fill={getTextColor(player.x, player.y)}
								align='center'
								width={WIDTH}
								height={HEIGHT}
							/>
						</Label>
					))}
				</Layer>
			</Stage>
			{!!match.can_edit && (
				<div className='flex flex-1 my-4'>
					<div className='flex flex-1 mr-2'>
						<AppButton
							loading={updatingTeams}
							text={'Save Teams'}
							className={'btn btn-primary w-full'}
							onClick={prepareFormations}
						/>
					</div>
					<div className='flex flex-1 ml-2'>
						<AppButton
							loading={updatingTeams}
							text={'Reset Teams'}
							className={'btn btn-secondary w-full'}
							onClick={resetPlayerPosition}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default Teams;
