import React from 'react';
import { createPopper } from '@popperjs/core';

const Tooltip = ({ type = 'small', placement = 'top', children, content }) => {
	const [tooltipShow, setTooltipShow] = React.useState(false);
	const btnRef = React.createRef();
	const tooltipRef = React.createRef();

	const openLeftTooltip = () => {
		createPopper(btnRef.current, tooltipRef.current, {
			placement,
		});
		setTooltipShow(true);
	};

	const closeLeftTooltip = () => {
		setTooltipShow(false);
	};

	const renderContent = () => {
		if (typeof content === 'string') {
			return content;
		}
		return content();
	};

	return (
		<div className='inline'>
			<div className='inline text-center'>
				<div
					className={'inline cursor-pointer'}
					onMouseEnter={openLeftTooltip}
					onMouseLeave={closeLeftTooltip}
					ref={btnRef}
				>
					{children}
				</div>

				<div
					className={
						(tooltipShow ? '' : 'hidden ') +
						`max-w-xs text-center no-underline break-words rounded-lg shadow-lg text-tiny text-white ${
							type === 'large' ? 'p-4' : 'p-1'
						}`
					}
					style={{ backgroundColor: 'rgba(27,149,27,0.8)' }}
					ref={tooltipRef}
				>
					{renderContent()}
				</div>
			</div>
		</div>
	);
};

export default Tooltip;
