import { components } from 'react-select';
import React from 'react';

const AutocompleteOption = ({ data, ...props }) => {
	return (
		<components.Option {...props}>
			<div className={'flex flex-1 flex-row px-1'}>
				<div className='flex flex-1 flex-col justify-center ml-2'>
					<p className={'m-0'}>{data.name} </p>
				</div>
			</div>
		</components.Option>
	);
};

export default AutocompleteOption;
