import Header from './Header';
import Footer from './Footer';
import { AppLoading } from '../App';

const AppLayout = ({ children, loading, containerClass, error, contentClass }) => {
	if (error) {
		if (error.errorCode === 404) {
			return '404';
		}
		if (error.errorCode === 401) {
			return '401';
		}
	}
	return (
		<div className={`tt-layout ${containerClass}`}>
			<Header />
			{error && error.message && <span>{error.message}</span>}
			{!!loading && (
				<div className={`tt-content ${contentClass}`}>
					<AppLoading height={'h-300'} />
				</div>
			)}
			{!loading && <div className={`tt-content ${contentClass}`}>{children}</div>}
			<Footer />
		</div>
	);
};

export default AppLayout;
