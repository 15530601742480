import { HashRouter as AppRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Login, NotAuthorized, NotFound } from '../views';
import { AuthorizedDefaultRoute, PublicRoutes, Routes, UnAuthorizedDefaultRoute } from './routes';
import { connect } from 'react-redux';

const Router = ({ user }) => {
	const isUserLoggedIn = () => {
		return user && user.id;
	};

	const ResolveRoutes = () => {
		return (
			<Switch>
				{Routes.map((route) => {
					if (route.meta && route.meta.roles && route.meta.roles.indexOf(user.role) === -1) {
						return null;
					}
					return (
						<Route
							key={route.path}
							path={route.path}
							exact={route.exact === true}
							render={(props) => {
								if (!isUserLoggedIn()) {
									return <Redirect to={UnAuthorizedDefaultRoute} />;
								}
								return <route.component {...props} />;
							}}
						/>
					);
				})}
				{PublicRoutes.map((route) => {
					return (
						<Route
							key={route.path}
							path={route.path}
							exact={route.exact === true}
							render={(props) => {
								return <route.component {...props} />;
							}}
						/>
					);
				})}
				{/* NotFound Error page */}
				<Route component={NotFound} />/
			</Switch>
		);
	};

	return (
		<AppRouter>
			<Switch>
				<Route
					exact
					path='/'
					render={() => {
						return isUserLoggedIn() ? (
							<Redirect to={AuthorizedDefaultRoute} />
						) : (
							<Redirect to={UnAuthorizedDefaultRoute} />
						);
					}}
				/>
				{/* Not Authenticated Routes */}
				<Route
					exact
					path='/login'
					render={() => {
						if (isUserLoggedIn()) {
							return <Redirect to={AuthorizedDefaultRoute} />;
						}
						return <Login />;
					}}
				/>
				<Route exact path='/not-authorized' render={() => <NotAuthorized />} />
				{/* Authenticated Routes */}
				{ResolveRoutes()}
			</Switch>
		</AppRouter>
	);
};

const mapStateToProps = ({ user }) => ({
	user: user.user,
});

const actions = {};

export default connect(mapStateToProps, actions)(Router);
