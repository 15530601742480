import React from 'react';

const Progress = ({ progress, total }) => {
	const roundedProgress = `${Math.ceil((progress / total) * 100)}%`;

	return (
		<div>
			<p className={'font-thin text-primary py-2'}>
				{progress} / {total}
			</p>
			<div className='w-full bg-gray-300'>
				<div
					className='h-2 bg-primary transition duration-500 ease-in-out '
					style={{ width: roundedProgress }}
				/>
			</div>
		</div>
	);
};
export default Progress;
