import { AppAutocomplete, AppButton, AppErrorMessage, AppInput, AppModal } from '../App';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { LocationAutocompleteOption } from '../Location';
import LocationService from '../../services/LocationService';
import debounce from 'debounce-promise';
import AppLabel from '../App/Label';

const InvitePlayerModal = ({ isOpen, onClose, onCreate }) => {
	const { loading, error } = useSelector(({ match }) => ({
		loading: match.creating,
		error: match.createError,
	}));

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm();

	const eraseForm = useCallback(() => {
		setValue('name', '');
		setValue('location_id', null);
		setValue('starts_at', null);
		setValue('number_of_players', 12);
		setValue('number_of_teams', 2);
	}, [setValue]);

	useEffect(() => {
		if (isOpen) {
			eraseForm();
		}
	}, [eraseForm, isOpen]);

	useEffect(() => {
		if (!loading) {
			eraseForm();
		}
	}, [eraseForm, loading]);

	const getLocations = async (search) => {
		return LocationService.list({ search });
	};

	return (
		<AppModal isOpen={isOpen} onRequestClose={onClose}>
			<div className={'flex flex-col'}>
				<AppErrorMessage message={error.message} />
				<form className={'w-full'} onSubmit={handleSubmit(onCreate)}>
					<AppInput
						label={'Name'}
						placeholder={'Name'}
						className={'w-full'}
						{...register('name', {
							required: true,
						})}
						errors={errors}
					/>
					<AppLabel label={'Location'} />
					<AppAutocomplete
						placeholder={'Start typing to search for locations...'}
						option={LocationAutocompleteOption}
						getOptionValue={(option) => option.id}
						getOptionLabel={(option) => option.name}
						loadOptions={debounce(getLocations, 500)}
						isClearable={true}
						cacheOptions={false}
						onChange={(i) => {
							setValue('location_id', i ? i.id : null, { shouldValidate: true });
						}}
					/>
					<AppInput
						label={'Date'}
						placeholder={'Date'}
						className={'w-full'}
						type={'datetime-local'}
						containerClassName={'mt-4'}
						{...register('starts_at', {
							required: true,
						})}
						errors={errors}
					/>
					<AppInput
						label={'Number of players'}
						placeholder={'Number of players'}
						className={'w-full'}
						containerClassName={'mt-4'}
						{...register('number_of_players', {
							required: true,
						})}
						errors={errors}
					/>
					<AppInput
						label={'Number of teams'}
						placeholder={'Number of teams'}
						className={'w-full'}
						containerClassName={'mt-4'}
						{...register('number_of_teams', {
							required: true,
						})}
						errors={errors}
					/>

					<AppButton
						type='submit'
						className={'btn btn-primary w-full mb-4'}
						text={'Create'}
						loading={loading}
					/>
				</form>
			</div>
		</AppModal>
	);
};

export default InvitePlayerModal;
