import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppLabel from '../App/Label';
import { AppAutocomplete } from '../App';
import debounce from 'debounce-promise';
import SportService from '../../services/SportService';
import { SportAutocompleteOption, SportLabel } from '../Sport';
import { addSportToMyProfile, removeSportToMyProfile } from '../../store/User';

const Sports = () => {
	const { user } = useSelector(({ user }) => ({
		user: user.user,
	}));

	const dispatch = useDispatch();

	const getSports = async (search) => {
		return SportService.list({ search });
	};

	const renderDescriptiveText = () => {
		if (!sports || sports.length === 0) {
			return "You haven't added any sports yet. Use the above input to connect your account with preferred sports. We will try to filter content on our system to show you only information which you might be interested on.";
		}
		return "This the list of sports you've selected until now. Use the above input to add more sports to the list or the cancel icon on the right side of each sport below to remove a sport from your interests.";
	};

	const { sports } = user;

	return (
		<div className={'mb-4'}>
			<AppLabel label={'Sport'} />
			<AppAutocomplete
				option={SportAutocompleteOption}
				getOptionValue={(option) => option.id}
				getOptionLabel={(option) => option.fullName}
				loadOptions={debounce(getSports, 500)}
				cacheOptions={false}
				clearOnSelect={true}
				onChange={({ id }) => {
					dispatch(addSportToMyProfile(id));
				}}
			/>
			<div className={'mt-4'}>
				<span>{renderDescriptiveText()}</span>
			</div>
			<div className='mt-4'>
				{sports.map((sport) => {
					return (
						<SportLabel
							className={'my-2'}
							key={sport.id}
							sport={sport}
							onRemove={() => dispatch(removeSportToMyProfile(sport.id))}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default Sports;
